import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';

const SignUp = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [nickname, setNickname] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [error, setError] = useState('');
  const [isConfirming, setIsConfirming] = useState(false);
  const navigate = useNavigate();
  const { signUp, confirmSignUp, isVerified, countdown, isAuthenticated } = useContext(AuthContext);

  // 추가: 인증 상태 감시
  useEffect(() => {
    if (isAuthenticated) {
      navigate('/');  // 인증되면 홈페이지로 이동
    }
  }, [isAuthenticated, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      await signUp(email, password, nickname);
      setIsConfirming(true);
    } catch (err) {
      console.error('Sign up error:', err);
      setError(`Error: ${err.message || 'Failed to sign up. Please try again.'}`);
    }
  };

  const handleConfirmSignUp = async (e) => {
    e.preventDefault();
    setError('');
    try {
      await confirmSignUp(email, verificationCode);
    } catch (err) {
      console.error('Confirmation error:', err);
      setError(`Error: ${err.message || 'Failed to confirm sign up. Please try again.'}`);
    }
  };

  if (isVerified) {
    return (
      <div className="auth-form-container">
        <h2>Account Verified Successfully!</h2>
        <p>You will be automatically logged in and redirected in {countdown} seconds.</p>
        <div className="progress-bar">
          <div 
            className="progress-bar-fill" 
            style={{ width: `${(countdown / 5) * 100}%` }}
          ></div>
        </div>
      </div>
    );
  }

  if (isConfirming) {
    return (
      <div className="auth-form-container">
        <h2>Verify Your Account</h2>
        {error && <p className="error-message">{error}</p>}
        <form onSubmit={handleConfirmSignUp}>
          <div>
            <label htmlFor="verificationCode">Verification Code:</label>
            <input
              type="text"
              id="verificationCode"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
              required
            />
          </div>
          <button type="submit">Verify Account</button>
        </form>
      </div>
    );
  }

  return (
    <div className="auth-form-container">
      <h2>Sign Up</h2>
      {error && <p className="error-message">{error}</p>}
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="nickname">Nickname:</label>
          <input
            type="text"
            id="nickname"
            value={nickname}
            onChange={(e) => setNickname(e.target.value)}
            required
          />
        </div>
        <button type="submit">Sign Up</button>
      </form>
    </div>
  );
};

export default SignUp;
