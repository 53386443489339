import React, { useState, useCallback } from 'react';
import './WordCount.css';

const WordCount = () => {
  const [text, setText] = useState('');
  const [wordCount, setWordCount] = useState(0);
  const [charCount, setCharCount] = useState(0);

  const countWords = useCallback((text) => {
    return text.trim().split(/\s+/).filter(word => word !== '').length;
  }, []);

  const countCharacters = useCallback((text) => {
    return text.length;
  }, []);

  const handleTextChange = (e) => {
    const newText = e.target.value;
    setText(newText);
    setWordCount(countWords(newText));
    setCharCount(countCharacters(newText));
  };

  return (
    <div className="word-count-container">
      <h1>Word Count Tool</h1>
      <textarea
        value={text}
        onChange={handleTextChange}
        placeholder="Enter your text here..."
        rows="20"
        className="text-input"
      />
      <div className="stats">
        <p>Words: {wordCount}</p>
        <p>Characters: {charCount}</p>
      </div>
    </div>
  );
};

export default WordCount;
