import React, { useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import targetIcon from '@iconify/icons-mdi/target';
import calculatorIcon from '@iconify/icons-mdi/calculator';
import chevronDown from '@iconify/icons-mdi/chevron-down';
import wordCountIcon from '@iconify/icons-mdi/file-word-outline'; // 새로 추가
import Calculator from './components/games/calculator/Calculator';
import './Playground.css'; // CSS 파일 이름 변경
import fileIcon from '@iconify/icons-mdi/file-document-outline';
import Modal from './Modal';
import Markdown from 'markdown-to-jsx';

const Playground = () => { // 컴포넌트 이름 변경
  const [showCalculator, setShowCalculator] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetch('/Calculator.md')
      .then(response => response.text())
      .then(md => {
        setModalContent(md);
        setIsLoading(false);
      })
      .catch(error => {
        console.error('Error loading the technical document:', error);
        setIsLoading(false);
      });
  }, []);

  const openGomokuGame = () => {
    window.open('/gomoku', 'Gomoku Game', 'width=900,height=900');
  };

  const openCalculator = () => {
    window.open('/calculator', 'Calculator', 'width=320,height=480');
  };

  const openWordCount = () => { // 새로 추가
    window.open('/word-count', 'Word Count', 'width=800,height=800');
  };

  const openTechDoc = () => {
    setShowModal(true);
  };

  const closeTechDoc = () => {
    setShowModal(false);
  };

  const games = [
    { 
      name: "Gomoku", 
      description: "Play Gomoku (Five in a Row)", 
      icon: targetIcon,
      color: "#8b4513", 
      status: "published",
      action: openGomokuGame 
    },
    { 
      name: "Calculator", 
      description: "Simple Calculator Application", 
      icon: calculatorIcon,
      color: "#4CAF50", 
      status: "published",
      action: openCalculator,
      techDoc: "Technical details of Calculator..."
    },
    { // 새로 추가
      name: "Word Count Tool", 
      description: "Count words and characters", 
      icon: wordCountIcon,
      color: "#3F51B5", 
      status: "published",
      action: openWordCount
    }
  ];

  return (
    <>
      <div className="hero-section playground-hero-section"> {/* 클래스 이름 변경 */}
        <div className="hero-content">
          <h1 className="main-title">Playground Central</h1>
          <p className="subtitle">"Small Games, Cool Features : Big Fun, Zen Moments"</p>
          <button className="cta-button">
            Scroll to Explore <Icon icon={chevronDown} />
          </button>
        </div>
      </div>
      <div className="playground"> {/* 클래스 이름 변경 */}
        <div className="grid-container">
          {games.map((game, index) => (
            <a key={index} href="#" className="grid-item" onClick={(e) => {e.preventDefault(); game.action();}}>
              <div className="item-content">
                <Icon icon={game.icon} style={{ fontSize: '3em', color: game.color }} />
                <h2>{game.name}</h2>
                <p>{game.description}</p>
              </div>
              <div className={`status status-${game.status}`}>
                <span className="status-text">{game.status}</span>
              </div>
              {game.techDoc && (
                <div className="tech-doc-icon" onClick={(e) => {e.stopPropagation(); openTechDoc();}}>
                  <Icon icon={fileIcon} />
                </div>
              )}
            </a>
          ))}
        </div>
      </div>
      <Modal show={showModal} onClose={closeTechDoc}>
        <div className="modal-scroll-content">
          {isLoading ? (
            <p>Loading...</p>
          ) : modalContent ? (
            <Markdown>{modalContent}</Markdown>
          ) : (
            <p>No content available</p>
          )}
        </div>
      </Modal>
      {showCalculator && (
        <div className="modal">
          <Calculator />
          <button onClick={() => setShowCalculator(false)}>Close</button>
        </div>
      )}
    </>
  );
};

export default Playground;