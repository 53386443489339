import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { confirmSignUp, signIn } from 'aws-amplify/auth';
import { useAuth } from '../hooks/useAuth';

const VerifyAccount = () => {
  const [code, setCode] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const email = location.state?.email || '';
  const { password } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      await confirmSignUp({ username: email, confirmationCode: code });
      // 인증 성공 후 자동 로그인 시도
      try {
        await signIn(email, password); // password는 상태로 관리하거나 다른 방법으로 얻어야 함
        navigate('/'); // 홈페이지로 리다이렉트
      } catch (signInError) {
        console.error('Error signing in after verification:', signInError);
        navigate('/signin'); // 로그인 페이지로 리다이렉트
      }
    } catch (err) {
      setError('Failed to verify account. Please check your code and try again.');
    }
  };

  return (
    <div className="auth-form-container">
      <h2>Verify Your Account</h2>
      {error && <p className="error-message">{error}</p>}
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            value={email}
            readOnly
          />
        </div>
        <div>
          <label htmlFor="code">Verification Code:</label>
          <input
            type="text"
            id="code"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            required
          />
        </div>
        <button type="submit">Verify Account</button>
      </form>
    </div>
  );
};

export default VerifyAccount;
