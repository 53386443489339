import React, { useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import awsIcon from '@iconify/icons-mdi/aws';
import azureIcon from '@iconify/icons-mdi/microsoft-azure';
import gcpIcon from '@iconify/icons-mdi/google-cloud';
import microphoneIcon from '@iconify/icons-mdi/microphone';
import lockIcon from '@iconify/icons-mdi/lock';
import serverIcon from '@iconify/icons-mdi/server';
import cubesIcon from '@iconify/icons-mdi/cube-outline';
import robotIcon from '@iconify/icons-mdi/robot';
import chevronDown from '@iconify/icons-mdi/chevron-down';
import fileIcon from '@iconify/icons-mdi/file-document-outline';
import pencilIcon from '@iconify/icons-mdi/pencil'; // IELTS Writing Practice 아이콘
import Modal from './Modal';
import './FunProject.css';
import Markdown from 'markdown-to-jsx';

const FunProject = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const [textToSpeechContent, setTextToSpeechContent] = useState('');
  const [ieltsWritingContent, setIeltsWritingContent] = useState('');

  useEffect(() => {
    Promise.all([
      fetch('/TextToSpeech.md').then(response => response.text()),
      fetch('/IELTSWriting.md').then(response => response.text())
    ])
      .then(([textToSpeechMd, ieltsWritingMd]) => {
        setTextToSpeechContent(textToSpeechMd);
        setIeltsWritingContent(ieltsWritingMd);
        setIsLoading(false);
      })
      .catch(error => {
        console.error('Error loading the technical documents:', error);
        setIsLoading(false);
      });
  }, []);

  const openTextToSpeech = () => {
    window.open('/text-to-speech', 'Text to Speech', 'width=600,height=400');
  };

  const openTechDoc = (projectName) => {
    if (projectName === "Text to Speech Conversion") {
      setModalContent(textToSpeechContent);
    } else if (projectName === "IELTS Writing Practice with AI-powered") {
      setModalContent(ieltsWritingContent);
    }
    setShowModal(true);
  };

  const closeTechDoc = () => {
    setShowModal(false);
  };

  const openIELTSWriting = () => {
    window.open('/ielts-writing', 'IELTS Writing Practice', 'width=880,height=700');
  };

  const projects = [
    { 
      name: "Text to Speech Conversion", 
      description: "Convert text to speech using AI", 
      icon: microphoneIcon, 
      color: "#1DB954", 
      status: "published",
      action: openTextToSpeech,
      techDoc: true
    },
    { 
      name: "IELTS Writing Practice with AI-powered", 
      description: "IELTS writing practice (General)", 
      icon: pencilIcon, 
      color: "#4A90E2", 
      status: "published",
      action: openIELTSWriting,
      techDoc: true
    },
    { name: "AWS Cloud Data Pipeline", description: "Data pipeline using AWS services", icon: awsIcon, color: "#FF9900", status: "planned" },
    { name: "Azure Cloud Data Pipeline", description: "Data pipeline using Azure services", icon: azureIcon, color: "#0089D6", status: "planned" },
    { name: "GCP Cloud Data Pipeline", description: "Data pipeline using Google Cloud", icon: gcpIcon, color: "#4285F4", status: "planned" },
    { name: "User Authentication by AWS Cognito", description: "Implement user auth with AWS Cognito", icon: lockIcon, color: "#FF9900", status: "planned" },
    { name: "AWS API Gateway with Dynamo DB", description: "Create APIs with AWS API Gateway and DynamoDB", icon: serverIcon, color: "#FF9900", status: "planned" },
    { name: "Terraform Automation Usecase & Demo", description: "Infrastructure as Code with Terraform", icon: cubesIcon, color: "#7B42BC", status: "planned" },
    { name: "Ansible Automation Usecase & Demo", description: "IT configuration automation with Ansible", icon: robotIcon, color: "#EE0000", status: "planned" }
  ];

  return (
    <>
      <div className="hero-section funproject-hero-section">
        <div className="hero-content">
          <h1 className="main-title">Cloud Project Showcase</h1>
          <p className="subtitle">"From Imagination To Implementation : Code Your Reality"</p>
          <button className="cta-button">
            Scroll to Explore <Icon icon={chevronDown} />
          </button>
        </div>
      </div>
      <div className="fun-project">
        <div className="grid-container">
          {projects.map((project, index) => (
            <a key={index} href="#" className="grid-item" onClick={(e) => {e.preventDefault(); project.action && project.action();}}>
              <div className="item-content">
                <Icon icon={project.icon} style={{ fontSize: '3em', color: project.color }} />
                <h2>{project.name}</h2>
                <p>{project.description}</p>
              </div>
              <div className={`status status-${project.status}`}>
                <span className="status-text">{project.status}</span>
              </div>
              {project.techDoc && (
                <div className="tech-doc-icon" onClick={(e) => {e.stopPropagation(); openTechDoc(project.name);}}>
                  <Icon icon={fileIcon} />
                </div>
              )}
            </a>
          ))}
        </div>
      </div>
      <Modal show={showModal} onClose={closeTechDoc}>
        <div className="modal-scroll-content">
          {isLoading ? (
            <p>Loading...</p>
          ) : modalContent ? (
            <Markdown>{modalContent}</Markdown>
          ) : (
            <p>No content available</p>
          )}
        </div>
      </Modal>
    </>
  );
};

export default FunProject;