import React, { createContext, useState, useEffect, useCallback } from 'react';
import { signIn, signOut, getCurrentUser, signInWithRedirect, fetchUserAttributes, confirmSignUp, signUp } from 'aws-amplify/auth';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [tempEmail, setTempEmail] = useState('');
  const [tempPassword, setTempPassword] = useState('');
  const [isVerified, setIsVerified] = useState(false);
  const [countdown, setCountdown] = useState(5);

  let isConfirming = false;

  async function checkUser() {
    try {
      const userData = await getCurrentUser();
      const attributes = await fetchUserAttributes();
      setUser({ 
        ...userData, 
        email: attributes.email,
        displayName: attributes.email.split('@')[0], // 이메일에서 @ 이전 부분 추출
        emailVerified: attributes.email_verified === 'true'
      });
      setIsAuthenticated(true);
    } catch (err) {
      setUser(null);
      setIsAuthenticated(false);
    }
  }

  async function handleSignIn(email, password) {
    const signInResult = await signIn({ username: email, password });
    await checkUser();
    return signInResult;
  }

  const handleAutoSignIn = useCallback(async () => {
    if (tempEmail && tempPassword) {
      try {
        console.log('Attempting auto sign-in');
        await handleSignIn(tempEmail, tempPassword);
        setTempEmail('');
        setTempPassword('');
        setIsAuthenticated(true);  // 추가: 인증 상태 업데이트
        return true;  // 추가: 성공 시 true 반환
      } catch (error) {
        console.error('Auto sign-in error:', error);
        return false;  // 추가: 실패 시 false 반환
      }
    }
    return false;  // 추가: 이메일이나 비밀번호가 없으면 false 반환
  }, [tempEmail, tempPassword, handleSignIn]);

  useEffect(() => {
    checkUser();
    const handleRedirectSignIn = async () => {
      try {
        await signInWithRedirect();
      } catch (err) {
        console.error('Error handling redirect sign-in:', err);
      } finally {
        checkUser();
      }
    };
    handleRedirectSignIn();
  }, []);

  useEffect(() => {
    let timer;
    if (isVerified && countdown > 0) {
      timer = setTimeout(() => setCountdown(countdown - 1), 1000);
    } else if (isVerified && countdown === 0) {
      handleAutoSignIn().then(success => {
        if (success) {
          // 추가: 자동 로그인 성공 시 필요한 작업 수행
          console.log('Auto sign-in successful');
        } else {
          // 추가: 자동 로그인 실패 시 처리
          console.log('Auto sign-in failed');
          setIsVerified(false);  // 상태 리셋
          setCountdown(5);  // 카운트다운 리셋
        }
      });
    }
    return () => clearTimeout(timer);
  }, [isVerified, countdown, handleAutoSignIn]);

  async function handleSignOut() {
    await signOut();
    setUser(null);
    setIsAuthenticated(false);
  }

  async function handleGoogleSignIn() {
    await signInWithRedirect({ provider: 'Google' });
  }

  async function handleSignUp(email, password) {
    const signUpResult = await signUp({
      username: email,
      password,
      attributes: {
        email,
        // 'custom:Nickname' 속성 제거
      },
    });
    setTempEmail(email);
    setTempPassword(password);
    return signUpResult;
  }

  async function handleConfirmSignUp(email, code) {
    if (isConfirming) return;
    isConfirming = true;
    console.log(`Confirming signup for email: ${email}`);
    try {
      await confirmSignUp({ username: email, confirmationCode: code });
      console.log('Signup confirmed successfully');
      setIsVerified(true);
      setCountdown(5);
    } catch (error) {
      console.error('Error in handleConfirmSignUp:', error);
      throw error;
    } finally {
      isConfirming = false;
    }
  }

  const value = {
    user,
    isAuthenticated,
    signIn: handleSignIn,
    signUp: handleSignUp,
    confirmSignUp: handleConfirmSignUp,
    signOut: handleSignOut,
    googleSignIn: handleGoogleSignIn,
    checkUser,
    setUser,
    isVerified,
    countdown,
    handleAutoSignIn,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
