import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCurrentUser, fetchUserAttributes } from 'aws-amplify/auth';
import { useAuth } from '../hooks/useAuth';

const AuthCallback = () => {
  const navigate = useNavigate();
  const { checkUser } = useAuth();

  useEffect(() => {
    const handleCallback = async () => {
      try {
        const user = await getCurrentUser();
        const attributes = await fetchUserAttributes();
        console.log('User authenticated:', user);
        console.log('User attributes:', attributes);
        
        await checkUser(); // AuthContext의 사용자 상태 업데이트
        navigate('/');
      } catch (error) {
        console.error('Error handling auth callback:', error);
        navigate('/signin');
      }
    };

    handleCallback();
  }, [navigate, checkUser]);

  return <div>Processing authentication...</div>;
};

export default AuthCallback;
