import React, { useState } from 'react';
import './Gomoku.css';

const BOARD_SIZE = 15;

const Gomoku = () => {
  const [stones, setStones] = useState([]);
  const [currentPlayer, setCurrentPlayer] = useState('B');
  const [winner, setWinner] = useState(null);
  const [showInstructions, setShowInstructions] = useState(true);

  const handleClick = (x, y) => {
    if (winner || stones.some(stone => stone.x === x && stone.y === y)) return;

    const newStones = [...stones, { x, y, player: currentPlayer }];
    setStones(newStones);

    if (checkWinner(newStones, x, y)) {
      setWinner(currentPlayer);
    } else {
      setCurrentPlayer(currentPlayer === 'B' ? 'W' : 'B');
    }
  };

  const checkWinner = (stones, x, y) => {
    const directions = [
      [1, 0], [0, 1], [1, 1], [1, -1]
    ];

    return directions.some(([dx, dy]) => {
      return countConsecutive(stones, x, y, dx, dy) + countConsecutive(stones, x, y, -dx, -dy) - 1 >= 5;
    });
  };

  const countConsecutive = (stones, x, y, dx, dy) => {
    const player = currentPlayer;
    let count = 0;
    let cx = x;
    let cy = y;

    while (cx >= 0 && cx < BOARD_SIZE && cy >= 0 && cy < BOARD_SIZE) {
      if (stones.some(stone => stone.x === cx && stone.y === cy && stone.player === player)) {
        count++;
        cx += dx;
        cy += dy;
      } else {
        break;
      }
    }

    return count;
  };

  const resetGame = () => {
    setStones([]);
    setCurrentPlayer('B');
    setWinner(null);
  };

  const closeInstructions = () => {
    setShowInstructions(false);
  };

  return (
    <div className="gomoku">
      <h2>Gomoku Game</h2>
      {showInstructions && (
        <div className="instructions">
          <h3>How to Play:</h3>
          <ol>
            <li>Black plays first, then players take turns.</li>
            <li>Click on an intersection to place your stone.</li>
            <li>The first player to form an unbroken chain of five stones horizontally, vertically, or diagonally wins.</li>
          </ol>
          <button onClick={closeInstructions}>Got it!</button>
        </div>
      )}
      <div className="board">
        {[...Array(BOARD_SIZE)].map((_, i) => (
          <div key={`h${i}`} className="h-line" style={{top: `${(i / (BOARD_SIZE - 1)) * 100}%`}} />
        ))}
        {[...Array(BOARD_SIZE)].map((_, i) => (
          <div key={`v${i}`} className="v-line" style={{left: `${(i / (BOARD_SIZE - 1)) * 100}%`}} />
        ))}
        {stones.map((stone, index) => (
          <div
            key={index}
            className={`stone ${stone.player}`}
            style={{
              left: `${(stone.x / (BOARD_SIZE - 1)) * 100}%`,
              top: `${(stone.y / (BOARD_SIZE - 1)) * 100}%`
            }}
          />
        ))}
        {[...Array(BOARD_SIZE * BOARD_SIZE)].map((_, index) => {
          const x = index % BOARD_SIZE;
          const y = Math.floor(index / BOARD_SIZE);
          return (
            <div
              key={index}
              className="intersection"
              style={{
                left: `${(x / (BOARD_SIZE - 1)) * 100}%`,
                top: `${(y / (BOARD_SIZE - 1)) * 100}%`
              }}
              onClick={() => handleClick(x, y)}
            />
          );
        })}
      </div>
      {winner && <p>Player {winner} wins!</p>}
      {!winner && <p>Current player: {currentPlayer}</p>}
      <button onClick={resetGame}>Reset Game</button>
    </div>
  );
};

export default Gomoku;
