import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios'; // axios를 사용하여 API 호출
import './TextToSpeech.css';

// Lambda 함수의 API 엔드포인트
const API_ENDPOINT = 'https://xcans3pc6f.execute-api.ca-central-1.amazonaws.com/TextToSpeechConverter';
const MAX_CHARS = 2500; // 최대 입력 문자 수
const DAILY_LIMIT = 15000; // 일일 제한
const MONTHLY_LIMIT = 800000; // 월간 제한

const TextToSpeech = () => {
  const [text, setText] = useState('');
  const [audioUrl, setAudioUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [charCount, setCharCount] = useState(0);
  const [dailyUsage, setDailyUsage] = useState(0);  // 새로운 상태 변수
  const [monthlyUsage, setMonthlyUsage] = useState(0);
  const textareaRef = useRef(null);

  useEffect(() => {
    adjustTextareaHeight();
  }, [text]);

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  const handleTextChange = (e) => {
    const inputText = e.target.value;
    if (inputText.length <= MAX_CHARS) {
      setText(inputText);
      setCharCount(inputText.length);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');
    setAudioUrl('');

    try {
      const response = await axios.post(API_ENDPOINT, {
        userid: 'user123', // 실제 사용시 사용자 ID를 동적으로 설정해야 합니다
        text: text
      }, {
        responseType: 'json'  // 이 부분을 추가하세요
      });

      const { audio, dailyUsage, monthlyUsage } = response.data;
      const audioBlob = new Blob([Uint8Array.from(atob(audio), c => c.charCodeAt(0))], { type: 'audio/mpeg' });
      const url = URL.createObjectURL(audioBlob);
      setAudioUrl(url);
      setDailyUsage(dailyUsage);  // 일일 사용량 업데이트
      setMonthlyUsage(monthlyUsage);  // 월간 사용량 업데이트
    } catch (error) {
      console.error('Error occurred during API call:', error);
      if (error.response) {
        console.error('Server response:', error.response.data);
        console.error('Status code:', error.response.status);
        setError(error.response.data.message || 'An error occurred');
      } else if (error.request) {
        console.error('Request was sent but no response was received');
        setError('No response received from the server');
      } else {
        console.error('Error setting up the request:', error.message);
        setError('Failed to send request');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="text-to-speech-popup">
      <h1>Text to Speech Conversion</h1>
      <form onSubmit={handleSubmit}>
        <textarea
          ref={textareaRef}
          value={text}
          onChange={handleTextChange}
          placeholder={`Enter text to convert to speech (max ${MAX_CHARS} characters)`}
          disabled={isLoading}
        />
        <p className="char-count">{charCount}/{MAX_CHARS}</p>
        <button type="submit" disabled={isLoading || charCount === 0 || charCount > MAX_CHARS}>
          {isLoading ? 'Converting...' : 'Convert to Speech'}
        </button>
      </form>
      <div className="usage-info">
        <p>Daily Usage: {dailyUsage} characters ({DAILY_LIMIT})</p>
        <p>Monthly Usage: {monthlyUsage} characters ({MONTHLY_LIMIT})</p>
      </div>
      {error && <p className="error-message">{error}</p>}
      {audioUrl && (
        <div className="audio-player">
          <audio controls src={audioUrl}>
            Your browser does not support the audio element.
          </audio>
        </div>
      )}
    </div>
  );
};

export default TextToSpeech;
