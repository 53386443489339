import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './IELTSWriting.css';

const API_ENDPOINT = 'https://aeb5m8s289.execute-api.ca-central-1.amazonaws.com/IELTSWriting';

const IELTSWriting = () => {
  const [taskType, setTaskType] = useState('');
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [timeLeft, setTimeLeft] = useState(0);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [feedback, setFeedback] = useState('');
  const [bandScore, setBandScore] = useState(0);
  const [sampleAnswer, setSampleAnswer] = useState('');
  const [wordCount, setWordCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const selectTask = async (type) => {
    setTaskType(type);
    setIsLoading(true);
    setError('');
    try {
      const response = await axios.post(API_ENDPOINT, {
        action: 'generateQuestion',
        taskType: type,
        userId: 'testUser123' // Add a temporary userId for testing
      });
      setQuestion(response.data.question);
      setTimeLeft(type === 'Task1' ? 1200 : 2400);
    } catch (error) {
      setError('Failed to generate question. Please try again.');
    }
    setIsLoading(false);
  };

  // 타이머 기능
  useEffect(() => {
    if (timeLeft > 0 && !isSubmitted) {
      const timerId = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
      return () => clearTimeout(timerId);
    }
  }, [timeLeft, isSubmitted]);

  // 답변 제출 함수
  const submitAnswer = async () => {
    setIsLoading(true);
    setError('');
    try {
      const response = await axios.post(API_ENDPOINT, {
        action: 'evaluateAnswer',
        taskType,
        question,
        answer,
        userId: 'user123' // Replace with actual user ID
      });
      if (response.data.error) {
        throw new Error(response.data.error);
      }
      setBandScore(response.data.bandScore);
      setFeedback(response.data.feedback);
      setSampleAnswer(response.data.sampleAnswer);
      setIsSubmitted(true);
    } catch (error) {
      console.error('Submission error:', error);
      setError(error.message || 'Failed to submit answer. Please try again.');
    }
    setIsLoading(false);
  };

  // 답변 변경 시 단어 수 계산
  const handleAnswerChange = (e) => {
    const text = e.target.value;
    setAnswer(text);
    setWordCount(text.trim().split(/\s+/).filter(word => word !== '').length);
  };

  const getRequiredWords = () => taskType === 'Task1' ? 150 : 250;

  return (
    <div className="ielts-writing-container">
      <h1>IELTS Writing Practice</h1>
      {error && <p className="error-message">{error}</p>}
      {!taskType && (
        <div className="task-selection">
          <button onClick={() => selectTask('Task1')} disabled={isLoading}>Task 1</button>
          <button onClick={() => selectTask('Task2')} disabled={isLoading}>Task 2</button>
        </div>
      )}
      {taskType && !isSubmitted && (
        <div className="writing-area">
          <h2>{taskType}</h2>
          <pre className="question-text">{question.trim()}</pre>
          <textarea
            value={answer}
            onChange={handleAnswerChange}
            placeholder="Write your answer here..."
            disabled={isLoading}
          />
          <p>Time left: {Math.floor(timeLeft / 60)}:{timeLeft % 60 < 10 ? '0' : ''}{timeLeft % 60}</p>
          <p>Word count: {wordCount} / {getRequiredWords()} required</p>
          <button 
            onClick={submitAnswer} 
            disabled={isLoading || (wordCount < getRequiredWords() && timeLeft > 0)}
          >
            {isLoading ? 'Submitting...' : 'Submit'}
          </button>
          <p className="submit-instruction">
            The Submit button will be activated once you write at least {getRequiredWords()} words for {taskType} or when the time runs out.
          </p>
        </div>
      )}
      {isSubmitted && (
        <div className="feedback-area">
          <h2>Feedback</h2>
          <div className="band-score">
            <h3>Band Score: {bandScore}</h3>
          </div>
          <div className="feedback-content">
            <h3>Evaluation:</h3>
            <p>{feedback}</p>
          </div>
          <div className="sample-answer">
            <h3>Sample Answer</h3>
            <p>{sampleAnswer}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default IELTSWriting;
