import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { signOut } from 'aws-amplify/auth';
import './SignIn.css';
import { FcGoogle } from 'react-icons/fc';

const SignIn = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { signIn, googleSignIn } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      await signOut(); // Sign out any existing user
      await signIn({ username: email, password });
      navigate('/');
    } catch (err) {
      if (err.name === 'UserNotConfirmedException') {
        setError('Email not verified. Please check your email for a verification code.');
        navigate('/verify-account', { state: { email } });
      } else {
        setError(`Failed to sign in: ${err.message}`);
      }
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      await googleSignIn();
    } catch (err) {
      setError('Failed to sign in with Google. Please try again.');
    }
  };

  return (
    <div className="auth-form-container">
      <h2>Sign In</h2>
      {error && <p className="error-message">{error}</p>}
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="password">Password:</label>
          <input
            type="password" 
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit">Sign In</button>
      </form>
      <div className="divider">or</div>
      <button onClick={handleGoogleSignIn} className="google-sign-in">
        <FcGoogle /> Sign in with Google
      </button>
    </div>
  );
};

export default SignIn;
