import React, { useState, useEffect } from 'react';
import './Calculator.css';

const Calculator = () => {
  const [display, setDisplay] = useState('0');
  const [currentInput, setCurrentInput] = useState('');
  const [firstOperand, setFirstOperand] = useState(null);
  const [operator, setOperator] = useState(null);
  const [waitingForSecondOperand, setWaitingForSecondOperand] = useState(false);
  const [activeKey, setActiveKey] = useState(null);

  const inputDigit = (digit) => {
    setCurrentInput(currentInput + digit);
    setDisplay(currentInput + digit);
  };

  const inputDecimal = () => {
    if (!currentInput.includes('.')) {
      setCurrentInput(currentInput + '.');
      setDisplay(currentInput + '.');
    }
  };

  const clear = () => {
    setDisplay('0');
    setCurrentInput('');
    setFirstOperand(null);
    setOperator(null);
    setWaitingForSecondOperand(false);
  };

  const inputPi = () => {
    setDisplay(Math.PI.toString());
  };

  const performOperation = (nextOperator) => {
    const inputValue = parseFloat(display);
    
    if (nextOperator === 'sqrt') {
      setDisplay(Math.sqrt(inputValue).toString());
      return;
    }
    
    if (firstOperand === null) {
      setFirstOperand(inputValue);
    } else if (operator) {
      const result = calculate(firstOperand, inputValue, operator);
      setFirstOperand(result);
      setDisplay(String(result));
    }

    if (nextOperator === '=') {
      setCurrentInput('');
      setOperator(null);
    } else {
      setCurrentInput('');
      setOperator(nextOperator);
      setDisplay(display + ' ' + nextOperator + ' ');
    }
    setWaitingForSecondOperand(true);
  };

  const calculate = (firstOperand, secondOperand, operator) => {
    switch (operator) {
      case '+': return firstOperand + secondOperand;
      case '-': return firstOperand - secondOperand;
      case '*': return firstOperand * secondOperand;
      case '/': return secondOperand !== 0 ? firstOperand / secondOperand : 'Error';
      default: return secondOperand;
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      const key = event.key;
      let buttonKey = key;

      if (/^[0-9]$/.test(key)) {
        inputDigit(parseInt(key));
      } else if (key === '.') {
        inputDecimal();
      } else if (key === '+' || key === '-' || key === '*' || key === '/') {
        performOperation(key);
      } else if (key === 'Enter' || key === '=') {
        event.preventDefault(); // Prevent form submission if calculator is in a form
        performOperation('=');
        buttonKey = '=';
      } else if (key === 'Escape' || key === 'c' || key === 'C') {
        clear();
        buttonKey = 'C';
      } else {
        return; // Exit if it's not a relevant key
      }

      setActiveKey(buttonKey);
      setTimeout(() => setActiveKey(null), 100); // Remove the effect after 100ms
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [inputDigit, inputDecimal, performOperation, clear]);

  return (
    <div className="calculator">
      <div className="calculator-display">{display}</div>
      <div className="calculator-keypad">
        <button className={`calculator-key key-clear ${activeKey === 'AC' ? 'active' : ''}`} onClick={clear}>AC</button>
        <button className="calculator-key" onClick={() => inputPi()}>π</button>
        <button className="calculator-key" onClick={() => performOperation('sqrt')}>√</button>
        <button className={`calculator-key key-operator ${activeKey === '÷' ? 'active' : ''}`} onClick={() => performOperation('/')}>÷</button>
        
        <button className={`calculator-key ${activeKey === '7' ? 'active' : ''}`} onClick={() => inputDigit(7)}>7</button>
        <button className={`calculator-key ${activeKey === '8' ? 'active' : ''}`} onClick={() => inputDigit(8)}>8</button>
        <button className={`calculator-key ${activeKey === '9' ? 'active' : ''}`} onClick={() => inputDigit(9)}>9</button>
        <button className={`calculator-key key-operator ${activeKey === '×' ? 'active' : ''}`} onClick={() => performOperation('*')}>×</button>
        
        <button className={`calculator-key ${activeKey === '4' ? 'active' : ''}`} onClick={() => inputDigit(4)}>4</button>
        <button className={`calculator-key ${activeKey === '5' ? 'active' : ''}`} onClick={() => inputDigit(5)}>5</button>
        <button className={`calculator-key ${activeKey === '6' ? 'active' : ''}`} onClick={() => inputDigit(6)}>6</button>
        <button className={`calculator-key key-operator ${activeKey === '-' ? 'active' : ''}`} onClick={() => performOperation('-')}>-</button>
        
        <button className={`calculator-key ${activeKey === '1' ? 'active' : ''}`} onClick={() => inputDigit(1)}>1</button>
        <button className={`calculator-key ${activeKey === '2' ? 'active' : ''}`} onClick={() => inputDigit(2)}>2</button>
        <button className={`calculator-key ${activeKey === '3' ? 'active' : ''}`} onClick={() => inputDigit(3)}>3</button>
        <button className={`calculator-key key-operator ${activeKey === '+' ? 'active' : ''}`} onClick={() => performOperation('+')}>+</button>
        
        <button className={`calculator-key ${activeKey === '0' ? 'active' : ''}`} onClick={() => inputDigit(0)} data-key="0">0</button>
        <button className={`calculator-key ${activeKey === '.' ? 'active' : ''}`} onClick={inputDecimal}>.</button>
        <button className={`calculator-key key-operator ${activeKey === '=' ? 'active' : ''}`} onClick={() => performOperation('=')}>=</button>
      </div>
    </div>
  );
};

export default Calculator;