import React from 'react';
import { Icon } from '@iconify/react';
import virtualizationIcon from '@iconify/icons-mdi/server-network';
import dockerIcon from '@iconify/icons-mdi/docker';
import linuxIcon from '@iconify/icons-mdi/linux';
import awsIcon from '@iconify/icons-mdi/aws';
import azureIcon from '@iconify/icons-mdi/microsoft-azure';
import gcpIcon from '@iconify/icons-mdi/google-cloud';
import kubernetesIcon from '@iconify/icons-mdi/kubernetes';
import windowsIcon from '@iconify/icons-mdi/microsoft-windows';
import terraformIcon from '@iconify/icons-mdi/terraform';
import ansibleIcon from '@iconify/icons-mdi/ansible';
import gitIcon from '@iconify/icons-mdi/git';
import securityIcon from '@iconify/icons-mdi/security';
import networkIcon from '@iconify/icons-mdi/network';
import chevronDown from '@iconify/icons-mdi/chevron-down';
import checkCircle from '@iconify/icons-mdi/check-circle';
import timerSand from '@iconify/icons-mdi/timer-sand';
import clockOutline from '@iconify/icons-mdi/clock-outline';
import './LandingPage.css';

const LandingPage = () => {
  const items = [
    { name: "Virtualization", link: "/virtualization.html", description: "Virtualization Concept", icon: virtualizationIcon, color: "#3498db", status: "published" },
    { name: "Docker", link: "/docker.html", description: "Containerization Concepts", icon: dockerIcon, color: "#2496ed", status: "published" },
    { name: "Docker Commands", link: "/docker-commands.html", description: "Essential Docker Commands", icon: dockerIcon, color: "#2496ed", status: "published" },
    { name: "Linux", link: "/linux.html", description: "Linux Basics", icon: linuxIcon, color: "#dd4814", status: "published" },
    { name: "Linux Commands", link: "/linux-commands.html", description: "Essential Linux Commands", icon: linuxIcon, color: "#dd4814", status: "published" },
    { name: "AWS", link: "/aws.html", description: "AWS Cloud Essential Concepts", icon: awsIcon, color: "#ff9900", status: "published" },
    { name: "Azure", link: "/azure.html", description: "Cloud Computing with Azure", icon: azureIcon, color: "#0089d6", status: "published" },
    { name: "GCP", link: "/gcp.html", description: "Cloud Computing with GCP", icon: gcpIcon, color: "#4285f4", status: "published" },
    { name: "Cloud Comparison", link: "/cloud-comparison.html", description: "AWS vs Azure vs GCP", icon: gcpIcon, color: "#34a853", status: "published" },
    { name: "Kubernetes", link: "/kubernetes.html", description: "Container Orchestration", icon: kubernetesIcon, color: "#326ce5", status: "planned" },
    { name: "Kubernetes Commands", link: "/kubernetes-commands.html", description: "Essential Kubernetes Commands", icon: kubernetesIcon, color: "#326ce5", status: "planned" },
    { name: "Windows OS", link: "/windows-os.html", description: "Windows Operating System", icon: windowsIcon, color: "#0078d7", status: "planned" },
    { name: "Windows Server", link: "/windows-server.html", description: "Windows Enterprise Solutions", icon: windowsIcon, color: "#0064d7", status: "planned" },
    { name: "Terraform", link: "/terraform.html", description: "Infrastructure as Code", icon: terraformIcon, color: "#7b42bc", status: "planned" },
    { name: "Ansible", link: "/ansible.html", description: "Configuration Management", icon: ansibleIcon, color: "#1A1918", status: "planned" },
    { name: "Git", link: "/git.html", description: "Version Control System", icon: gitIcon, color: "#F1502F", status: "planned" },
    { name: "Cloud Security", link: "/cloud-security.html", description: "Securing Cloud Infrastructure", icon: securityIcon, color: "#FFD700", status: "planned" },
    { name: "Networking1", link: "/networking-essential.html", description: "Essential Networking Technologies", icon: networkIcon, color: "#1abc9c", status: "planned" },
    { name: "Networking2", link: "/networking-sdn-nfv.html", description: "Modern Networking (SDN & NFV)", icon: networkIcon, color: "#3498db", status: "planned" },
    { name: "Networking3", link: "/networking-blockchain.html", description: "Blockchain Networks", icon: networkIcon, color: "#e74c3c", status: "planned" },
    { name: "Sample", link: "/sample.html", description: "Sample Page Format", icon: dockerIcon, color: "#34495e", status: "planned" }
  ];

  return (
    <>
      <div className="hero-section landing-hero-section">
        <div className="hero-content">
          <h1 className="main-title">Cloud Computing Tech Notes</h1>
          <p className="subtitle">"Crisp Notes, Clear Concepts : Your Cloud Study Companion"</p>
          <button className="cta-button">
            Scroll to Explore <Icon icon={chevronDown} />
          </button>
        </div>
      </div>
      <div className="landing-page">
        <div className="grid-container">
          {items.map((item, index) => (
            <a key={index} href={item.link} className="grid-item">
              <div className="item-content">
                <Icon icon={item.icon} style={{ fontSize: '3em', color: item.color }} />
                <h2>{item.name}</h2>
                <p>{item.description}</p>
              </div>
              <div className={`status status-${item.status}`}>
                <span className="status-text">{item.status}</span>
              </div>
            </a>
          ))}
        </div>
      </div>
    </>
  );
};

export default LandingPage;