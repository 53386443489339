import React from 'react';
import './Contact.css';
import { FaEnvelope } from 'react-icons/fa'; // react-icons 라이브러리 사용

const Contact = () => {
  return (
    <div className="contact-page">
      <div className="contact-content">
        <FaEnvelope className="email-icon" />
        <span className="email-text">
          admin@cloudtechnote.com
        </span>
      </div>
    </div>
  );
};

export default Contact;
