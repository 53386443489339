// Importing the React library to create components
import React from 'react';
// Importing ReactDOM to render components to the DOM
import ReactDOM from 'react-dom';
// Importing the App component, which is the main component of the app
import App from './App';

// Rendering the App component inside the HTML element with id 'root'
// <React.StrictMode> is used to help detect potential issues in the app during development
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  // Targeting the HTML element with id 'root' where the App will be displayed
  document.getElementById('root')
);