import { Amplify } from 'aws-amplify';

const awsconfig = {
  Auth: {
    Cognito: {
      region: 'ca-central-1',
      userPoolId: 'ca-central-1_93ZDH3zOW',
      userPoolClientId: '6lv547oc6hihr0eqo5kvgtfsln',
      mandatorySignIn: true,
      signUpVerificationMethod: 'code', // 이메일 인증 코드 추가
      oauth: {
        domain: 'auth.cloudtechnote.com',
        scope: ['email', 'profile', 'openid'],
        redirectSignIn: [
          'http://localhost:3000/auth/google/callback',
          'https://www.cloudtechnote.com/auth/google/callback'
        ],
        redirectSignOut: [
          'http://localhost:3000',
          'https://www.cloudtechnote.com'
        ],
        responseType: 'code'
      }
    },
    OAuth: {
      redirectSignIn: [
        'http://localhost:3000/auth/google/callback',
        'https://www.cloudtechnote.com/auth/google/callback'
      ],
      redirectSignOut: [
        'http://localhost:3000',
        'https://www.cloudtechnote.com'
      ],
      responseType: 'code'
    },
    federationTarget: 'COGNITO_USER_POOLS',
    socialProviders: ['Google']
  }
};

console.log('Amplify configuration:', JSON.stringify(awsconfig, null, 2));
console.log('OAuth configuration:', JSON.stringify(awsconfig.Auth.oauth, null, 2));

Amplify.configure(awsconfig);

export default awsconfig;
