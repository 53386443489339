import React, { useEffect, useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from 'react-router-dom';
import LandingPage from './LandingPage';
import FunProject from './FunProject';
import Playground from './Playground';
import Navigation from './Navigation';
import Gomoku from './components/games/gomoku/Gomoku';
import TextToSpeech from './components/projects/TextToSpeech/TextToSpeech';
import WordCount from './components/games/WordCount/WordCount';
import Contact from './Contact';
import CalculatorPage from './components/games/calculator/CalculatorPage';
import IELTSWriting from './components/projects/IELTSWriting/IELTSWriting';
import { AuthProvider } from './components/contexts/AuthContext';
import SignIn from './components/auth/SignIn';
import SignUp from './components/auth/SignUp';
import VerifyAccount from './components/auth/VerifyAccount';
import ForgotPassword from './components/auth/ForgotPassword';
import AuthCallback from './components/auth/AuthCallback';
import { Amplify } from 'aws-amplify';
import awsconfig from './amplifyConfig';
import { Hub } from 'aws-amplify/utils';
import { fetchAuthSession } from 'aws-amplify/auth';
import { AuthContext } from './components/contexts/AuthContext'; // AuthContext import 추가

console.log('Amplify configuration in App.js:', JSON.stringify(awsconfig, null, 2));
Amplify.configure(awsconfig);

const AppContent = () => {
  const location = useLocation();
  const { isAuthenticated, checkUser } = useContext(AuthContext);
  const showNavigation = !['/gomoku', '/text-to-speech', '/calculator', '/word-count', '/ielts-writing'].includes(location.pathname);

  useEffect(() => {
    console.log('App.js: Configuring auth listeners');
    const handleRedirectSignIn = async () => {
      try {
        console.log('Handling redirect sign-in');
        const { tokens } = await fetchAuthSession();
        if (tokens) {
          console.log('Successfully signed in after redirect');
          await checkUser();
        }
      } catch (error) {
        console.error('Error handling redirect sign-in:', error);
      }
    };

    Hub.listen('auth', ({ payload }) => {
      const { event } = payload;
      if (event === 'signInWithRedirect') {
        handleRedirectSignIn();
      }
    });

    // 초기 로드 시 한 번만 실행
    handleRedirectSignIn();
  }, []);  // checkUser 의존성 제거

  return (
    <>
      {showNavigation && <Navigation />}
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/fun-project" element={<FunProject />} />
        <Route path="/playground" element={<Playground />} />
        <Route path="/gomoku" element={<Gomoku />} />
        <Route path="/text-to-speech" element={<TextToSpeech />} />
        <Route path="/word-count" element={<WordCount />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/calculator" element={<CalculatorPage />} />
        <Route path="/ielts-writing" element={<IELTSWriting />} />
        <Route path="/signin" element={!isAuthenticated ? <SignIn /> : <Navigate to="/" />} />
        <Route path="/signup" element={!isAuthenticated ? <SignUp /> : <Navigate to="/" />} />
        <Route path="/verify-account" element={<VerifyAccount />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/auth/google/callback" element={<AuthCallback />} />
      </Routes>
    </>
  );
};

const App = () => {
  return (
    <Router>
      <AuthProvider>
        <AppContent />
      </AuthProvider>
    </Router>
  );
};

export default App;
