import React, { useState, useContext, useCallback, useRef, useEffect } from 'react';
import { debounce } from 'lodash';
import { FcGoogle } from 'react-icons/fc';
import './LoginModal.css';
import './SignUp.css'; // SignUp.css 파일을 import 합니다
import { AuthContext } from '../contexts/AuthContext';
import { Amplify } from 'aws-amplify';
import { resendSignUpCode } from 'aws-amplify/auth';

const LoginModal = ({ onClose }) => {
  const [isLogin, setIsLogin] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [signUpSuccess, setSignUpSuccess] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [isResendingCode, setIsResendingCode] = useState(false);
  const [countdown, setCountdown] = useState(5);
  const [isVerified, setIsVerified] = useState(false);
  const { signIn, signUp, confirmSignUp, googleSignIn, checkUser, setUser, handleAutoSignIn } = useContext(AuthContext);
  const isRequestPending = useRef(false);

  const validatePasswords = () => {
    if (password !== confirmPassword) {
      setError("Passwords don't match");
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);
    try {
      if (isLogin) {
        await signIn(email, password);
        await checkUser();
        onClose();
      } else {
        if (!validatePasswords()) {
          setIsLoading(false);
          return;
        }
        await signUp(email, password);
        setSignUpSuccess(true);
      }
    } catch (err) {
      console.error('Authentication error:', err);
      setError(err.message || (isLogin ? 'Failed to sign in' : 'Failed to sign up'));
    } finally {
      setIsLoading(false);
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      setIsLoading(true);
      setError('');
      await googleSignIn();
    } catch (err) {
      console.error('Error in handleGoogleSignIn:', err);
      setError(`Failed to sign in with Google: ${err.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleConfirmSignUp = useCallback(async () => {
    if (isLoading || isRequestPending.current) return;
    isRequestPending.current = true;
    setIsLoading(true);
    setError('');

    try {
      await confirmSignUp(email, verificationCode);
      setIsVerified(true);
      setCountdown(5);
    } catch (err) {
      console.error('Confirmation error:', err);
      if (err.name === 'LimitExceededException') {
        setError('Too many attempts. Please wait a moment and try again.');
      } else {
        setError(`Error: ${err.message || 'Failed to confirm sign up. Please try again.'}`);
      }
    } finally {
      setIsLoading(false);
      isRequestPending.current = false;
    }
  }, [email, verificationCode, confirmSignUp]);

  useEffect(() => {
    let timer;
    if (isVerified && countdown > 0) {
      timer = setTimeout(() => setCountdown(countdown - 1), 1000);
    } else if (isVerified && countdown === 0) {
      handleAutoSignIn().then(() => {
        checkUser();
        onClose();
      }).catch(err => {
        console.error('Auto sign-in error:', err);
        setError('Auto sign-in failed. Please try logging in manually.');
      });
    }
    return () => clearTimeout(timer);
  }, [isVerified, countdown, handleAutoSignIn, checkUser, onClose]);

  const handleResendCode = async () => {
    setIsResendingCode(true);
    setError('');
    try {
      await resendSignUpCode({ username: email });
      setError('A new verification code has been sent to your email.');
    } catch (err) {
      console.error('Error resending code:', err);
      setError(`Failed to resend code: ${err.message}`);
    } finally {
      setIsResendingCode(false);
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        {signUpSuccess ? (
          <>
            <h2>Verify Your Account</h2>
            {isVerified ? (
              <>
                <p>Your account has been verified successfully!</p>
                <p>You will be automatically logged in after <span className="countdown-number">{countdown}</span> seconds.</p>
                <div className="progress-bar">
                  <div 
                    className="progress-bar-fill" 
                    style={{ width: `${((5 - countdown) / 5) * 100}%` }}
                  ></div>
                </div>
              </>
            ) : (
              <>
                <p>A verification code has been sent to your email. Please check your inbox and enter the code to complete the registration.</p>
                <input
                  type="text"
                  value={verificationCode}
                  onChange={(e) => setVerificationCode(e.target.value)}
                  placeholder="Enter verification code"
                  disabled={isLoading}
                />
                <div className="button-group">
                  <button 
                    onClick={handleConfirmSignUp} 
                    className="login-button" 
                    disabled={isLoading || !verificationCode}
                  >
                    {isLoading ? 'Verifying...' : 'Verify Account'}
                  </button>
                  <button
                    onClick={handleResendCode}
                    className="resend-button"
                    disabled={isLoading || isResendingCode}
                  >
                    {isResendingCode ? 'Sending...' : 'Resend'}
                  </button>
                </div>
              </>
            )}
            {error && <p className="error-message">{error}</p>}
          </>
        ) : (
          <>
            <h2>{isLogin ? 'Log in' : 'Sign up'}</h2>
            {error && <p className="error-message">{error}</p>}
            <form onSubmit={handleSubmit}>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                required
              />
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                required
              />
              {!isLogin && (
                <input
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  placeholder="Confirm Password"
                  required
                />
              )}
              <button type="submit" className="login-button" disabled={isLoading}>
                {isLoading ? 'Processing...' : (isLogin ? 'Log in' : 'Sign up')}
              </button>
            </form>
            <div className="divider">or</div>
            <button onClick={handleGoogleSignIn} className="google-sign-in">
              <FcGoogle /> Sign in with Google
            </button>
            <p className="switch-mode">
              {isLogin ? "Don't have an account? " : "Already have an account? "}
              <button onClick={() => {
                setIsLogin(!isLogin);
                setError('');
                setPassword('');
                setConfirmPassword('');
              }}>
                {isLogin ? 'Sign up' : 'Log in'}
              </button>
            </p>
            <button onClick={onClose} className="close-button">×</button>
          </>
        )}
      </div>
    </div>
  );
};

export default LoginModal;
