import { useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { signInWithRedirect } from 'aws-amplify/auth';

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  // googleSignIn 함수 추가
  const googleSignIn = async () => {
    try {
      await signInWithRedirect({ provider: 'Google' });
    } catch (error) {
      console.error('Error during Google sign in:', error);
      throw error;
    }
  };

  return { ...context, googleSignIn };
};
