import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useAuth } from './components/hooks/useAuth';
import './Navigation.css';
import LoginModal from './components/auth/LoginModal';

const Navigation = () => {
  const { isAuthenticated, user, signOut } = useAuth();
  const [showLoginModal, setShowLoginModal] = useState(false);

  const handleSignOut = async () => {
    try {
      await signOut();
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const getDisplayName = () => {
    if (user && user.email) {
      return user.email.split('@')[0]; // 이메일의 @ 이전 부분을 사용
    }
    return 'User';
  };

  return (
    <nav className="top-nav">
      <div className="nav-container">
        <NavLink to="/" className="logo">CloudTechNote</NavLink>
        <ul className="nav-menu">
          <li><NavLink to="/fun-project">Fun Project</NavLink></li>
          <li><NavLink to="/playground">Playground</NavLink></li>
          <li><NavLink to="/contact">Contact Us</NavLink></li>
        </ul>
        <div className="auth-section">
          {isAuthenticated && user ? (
            <>
              <span className="welcome-message">Welcome, {getDisplayName()}</span>
              <button onClick={handleSignOut} className="auth-button">Log Out</button>
            </>
          ) : (
            <button onClick={() => setShowLoginModal(true)} className="auth-button">Log In</button>
          )}
        </div>
      </div>
      {showLoginModal && <LoginModal onClose={() => setShowLoginModal(false)} />}
    </nav>
  );
};

export default Navigation;
